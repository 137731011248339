import { FORM_PAYMENT_DETAILS_SUBMIT } from "actions/paymentStateActionTypes";
import initialState from "./initialState";

export default function paymentStateReducer(
  state = initialState.paymentState,
  action,
) {
  switch (action.type) {
    case FORM_PAYMENT_DETAILS_SUBMIT:
      return Object.assign({}, state, action.payload);
    default:
      return state;
  }
}
