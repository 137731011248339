import {
  SET_ADDRESS_DETAILS,
  SET_ADDRESS_ISLAND_STATUS,
  SET_ADDRESS_TYPE,
  SET_MANUAL_ADDRESS_DETAILS,
  SET_REGISTRY_ADDRESS_DETAILS,
  UNSET_ADDRESS_DETAILS,
} from "actions/addressState/addressStateActionTypes";
import initialState from "./initialState";

export default function addressStateReducer(
  state = initialState.addressState,
  action,
) {
  switch (action.type) {
    case SET_ADDRESS_TYPE: {
      return Object.assign({}, state, { addressType: action.payload });
    }
    case SET_ADDRESS_DETAILS: {
      return Object.assign({}, state, { addressDetails: action.payload });
    }
    case SET_REGISTRY_ADDRESS_DETAILS: {
      return Object.assign({}, state, {
        registryAddressDetails: action.payload,
      });
    }
    case SET_MANUAL_ADDRESS_DETAILS: {
      return Object.assign({}, state, { manualAddressDetails: action.payload });
    }
    case UNSET_ADDRESS_DETAILS: {
      return Object.assign({}, initialState.addressState);
    }
    case SET_ADDRESS_ISLAND_STATUS: {
      return Object.assign({}, state, {
        isAddressInNorthIsland: action.payload,
      });
    }
    default:
      return state;
  }
}
