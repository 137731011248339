import { combineReducers } from "redux";
import addressState from "./addressStateReducer";
import appPropertyState from "./appPropertyStateReducer";
import appState from "./appStateReducer";
import appUserState from "./appUserStateReducer";
import businessOffers from "./businessOffersReducer";
import businessPlanConfig from "./businessPlanConfigReducer";
import businessState from "./businessStateReducer";
import carRegistration from "./carRegistrationReducer";
import gtmState from "./gtmReducer";
import icp from "./icpReducer";
import paymentState from "./paymentStateReducer";
import residentialOffers from "./residentialOffersReducer";
import residentialPlanConfig from "./residentialPlanConfigReducer";
import zendeskState from "./zendeskReducer";
import { CLEAR_STATE } from "actions/clearStateActionTypes";
import initialState from "./initialState";

const appReducer = combineReducers({
  addressState,
  appPropertyState,
  appState,
  appUserState,
  businessOffers,
  businessPlanConfig,
  businessState,
  carRegistration,
  gtmState,
  icp,
  paymentState,
  residentialOffers,
  residentialPlanConfig,
  zendeskState,
});

const rootReducer = (state, action) => {
  if (action.type === CLEAR_STATE) {
    state = {
      ...initialState, // clear personal details / bank details
      // keep state for SuccessPage to displayed tailored content
      appState: {
        ...initialState.appState,
        planType: state.appState.planType,
        planUsageType: state.appState.planUsageType,
        products: state.appState.products,
        recentlyFailedApi: state.appState.recentlyFailedApi,
      },
      appUserState: {
        ...initialState.appUserState,
        isExistingCustomer: state.appUserState.isExistingCustomer,
        paymentMethod: state.appUserState.paymentMethod,
      },
      residentialPlanConfig: state.residentialPlanConfig,
      residentialOffers: state.residentialOffers,
      businessPlanConfig: state.businessPlanConfig,
      businessOffers: state.businessOffers,
      gtmState: state.gtmState,
    };
  }

  return appReducer(state, action);
};

export default rootReducer;
