import {
  ADDRESS_TYPE_ADDRESS_FINDER,
  BUSINESS_PLAN_TERM_FIXED12,
  BUSINESS_PLAN_TERM_FIXED12_DISCOUNT,
  BUSINESS_PLAN_TERM_FIXED24,
  BUSINESS_PLAN_TERM_FIXED24_DISCOUNT,
  BUSINESS_PLAN_TERM_FLEXI,
  BUSINESS_PLAN_TERM_PERIOD_FIXED12,
  BUSINESS_PLAN_TERM_PERIOD_FIXED24,
  BUSINESS_PLAN_TERM_PERIOD_FLEXI,
  PLAN_USAGE_LOW,
  PRODUCT_ELECTRICITY,
  PRODUCT_ELECTRICITY_DISPLAY_NAME,
  PRODUCT_GAS,
  PRODUCT_GAS_DISPLAY_NAME,
  PRODUCT_LPG,
  PRODUCT_LPG_DISPLAY_NAME,
  RESIDENTIAL_PLAN_TERM_FIXED12,
  RESIDENTIAL_PLAN_TERM_FLEXI,
} from "helpers/constants";

const initialState = {
  addressState: {
    __persist: true,
    addressType: ADDRESS_TYPE_ADDRESS_FINDER,
    addressDetails: null,
    registryAddressDetails: null,
    manualAddressDetails: {
      addressLine1: "",
      addressLine2: "",
      suburb: "",
      town: "",
      postcode: "",
    },
    isAddressInNorthIsland: true,
  },
  appState: {
    __persist: true,
    customerType: null,
    numberOfAdults: 1,
    numberOfChildren: 0,
    isPrimaryResidence: true,
    planType: null,
    planUsageType: PLAN_USAGE_LOW,
    recentlyFailedApi: "",
    products: [
      {
        name: PRODUCT_ELECTRICITY,
        displayName: PRODUCT_ELECTRICITY_DISPLAY_NAME,
        isAvailable: true,
        isSelected: true,
        isDefaultSelected: true,
      },
      {
        name: PRODUCT_GAS,
        displayName: PRODUCT_GAS_DISPLAY_NAME,
        isAvailable: true,
        isSelected: false,
        isDefaultSelected: false,
      },
      {
        name: PRODUCT_LPG,
        displayName: PRODUCT_LPG_DISPLAY_NAME,
        isAvailable: true,
        isSelected: false,
        isDefaultSelected: false,
      },
    ],
  },
  appUserState: {
    __persist: true,
    title: "",
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    phone: "",
    isEBillingPreference: true,
    isPromoByEmail: "",
    isExistingCustomer: "",
    generalTermsForExistingCustomers: true,
    isDriverLicence: "",
    customerNumber: "",
    licenceNo: "",
    licenceVersionNo: "",
    dob: "",
    itemGUID: "", // lead generation id
    signupForAdditionalPropertiesOrIcps: false,
    callbackReason: "",
    paymentMethod: "",
    fixedPaymentDay: "",
    regNumber: "",
    carDealership: "",
  },
  carRegistration: {
    __persist: true,
    plate: "", // "HAX476",
    colour: "", // "White",
    make: "", // "BMW",
    model: "", // "I",
    year: "", // "2022",
    isEligibleForEv: false,
    carDealership: "",
  },
  appPropertyState: {
    __persist: true,
    situation: "",
    moveInDate: "",
    meterAccessHasAnIssue: "",
    meterAccessIssueReasons: [],
    meterAccessIssueReasonOther: "",
    isConnectedToSolar: "",
    isMedicalSupport: "",
    isWellBeing: "",
    wellBeingAffectedReason: "",
    gasBottlesCount: "",
    gasBottlesSupplier: "",
    orderBottlesOption: "",
    gasBottlesLocation: "",
    gasBottleSize: "",
  },
  businessState: {
    __persist: true,
    businessType: "",
    soleTraderTradingAs: "",
    companyTradingAs: "",
    otherTradingAs: "",
    companyNZBN: null,
  },
  businessPlanConfig: {
    __persist: true,
    termData: [
      {
        codeName: BUSINESS_PLAN_TERM_FIXED24,
        displayName: "24-month fixed term",
        sidebarDisplayName: "24-month, fixed-term",
        termPeriod: BUSINESS_PLAN_TERM_PERIOD_FIXED24,
        discountPercentage: BUSINESS_PLAN_TERM_FIXED24_DISCOUNT,
        isSelected: false,
      },
      {
        codeName: BUSINESS_PLAN_TERM_FIXED12,
        displayName: "12-month fixed term",
        sidebarDisplayName: "12-month, fixed-term",
        termPeriod: BUSINESS_PLAN_TERM_PERIOD_FIXED12,
        discountPercentage: BUSINESS_PLAN_TERM_FIXED12_DISCOUNT,
        isSelected: false,
      },
      {
        codeName: BUSINESS_PLAN_TERM_FLEXI,
        displayName: "Flexible, no fixed term",
        sidebarDisplayName: "Flexible, no discount",
        termPeriod: BUSINESS_PLAN_TERM_PERIOD_FLEXI,
        discountPercentage: 0,
        isSelected: false,
      },
    ],
  },
  businessOffers: {
    __persist: true,
    electricityOffers: [],
    electricityOffersResult: {},
    naturalGasOffers: [],
    naturalGasOffersResult: {},
    bottledGasOffers: [],
    bottledGasOffersResult: {},
  },
  residentialPlanConfig: {
    __persist: true,
    termData: [
      {
        codeName: RESIDENTIAL_PLAN_TERM_FIXED12,
        displayName: "12-month fixed term",
        sidebarDisplayName: "12-month signup",
        discountPercentage: 3,
        isSelected: false,
      },
      {
        codeName: RESIDENTIAL_PLAN_TERM_FLEXI,
        displayName: "Flexible, no fixed term",
        sidebarDisplayName: "Flexible, no fixed-term credit",
        discountPercentage: 0,
        isSelected: false,
      },
    ],
  },
  residentialOffers: {
    electricityOffersResult: {},
    naturalGasOffersResult: {},
    bottledGasOffersResult: {},
    __persist: true,
    electricityOffer: {
      basicPlan: {
        lowUsage: {
          tariffList: [],
        },
        highUsage: {
          tariffList: [],
        },
      },
      plusPlan: {
        lowUsage: {
          tariffList: [],
        },
        highUsage: {
          tariffList: [],
        },
      },
      evPlan: {
        lowUsage: {
          tariffList: [],
        },
        highUsage: {
          tariffList: [],
        },
      },
    },
    naturalGasOffer: {
      basicPlan: {
        lowUsage: {
          tariffList: [],
        },
        highUsage: {
          tariffList: [],
        },
      },
      plusPlan: {
        lowUsage: {
          tariffList: [],
        },
        highUsage: {
          tariffList: [],
        },
      },
    },
    bottledGasOffer: {
      basicPlan: {
        lowUsage: {
          tariffList: [],
        },
        highUsage: {
          tariffList: [],
        },
      },
      plusPlan: {
        lowUsage: {
          tariffList: [],
        },
        highUsage: {
          tariffList: [],
        },
      },
    },
  },
  browser: {
    __persist: false,
  },
  icp: {
    __persist: true,
    elecIcp: "",
    elecMeterId: "",
    elecHasMultipleResults: false,
    elecRegistryAddress: null,
    gasIcp: "",
    gasMeterId: "",
    gasHasMultipleResults: false,
    gasRegistryAddress: null,
    lpgIcp: "",
    lpgHasMultipleResults: false,
    result: {},
  },
  paymentState: {
    __persist: false,
    sessionId: "",
    formUrl: "",
    tokenId: "",
    expiryDate: "",
    responseText: "",
    bankAccountNo: "",
    bankAccountName: "",
    bankName: "",

    creditCard: "", // credit card number
    creditCardType: "", // credit card type
    expiryMmYy: "", // expiry month/year,
    cvc: "", // cvc
    isApiError: false,
    isCcInvalid: false,
    generalTerms: false,
    directDebitSoleSigningAuthority: false,
    directDebitTerms: false,
  },
  zendeskState: {
    __persist: true,
    ticketId: "",
    utm: {
      utmSource: "",
      utmMedium: "",
      utmCampaign: "",
      dclid: "",
      gclid: "",
    },
  },
  gtmState: {
    businessEcommerce: {
      selectedProducts: "",
      selectedTermName: "",
      meanFixedCharges: 0,
      paymentType: "",
      postalCode: "",
      transactionId: "",
    },
    residentialEcommerce: {
      selectedProducts: "",
      selectedTermName: "",
      meanFixedCharges: 0,
      postalCode: "",
      transactionId: "",
      selectedPlanType: "",
      energyUsageType: "",
      householdSize: "",
      isExistingCustomerSignup: false,
    },
  },
};

export default initialState;
