import { FORM_PROPERTY_DETAILS_SUBMIT } from "actions/appPropertyActionTypes";
import initialState from "./initialState";

export default function appPropertyStateReducer(
  state = initialState.appPropertyState,
  action,
) {
  switch (action.type) {
    case FORM_PROPERTY_DETAILS_SUBMIT: {
      return Object.assign({}, state, action.payload);
    }
    default:
      return state;
  }
}
