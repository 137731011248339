export const FORM_PERSONAL_DETAILS_SUBMIT =
  "appUserState/FORM_PERSONAL_DETAILS_SUBMIT";

export const FORM_CREDIT_CHECK_SUBMIT = "appUserState/FORM_CREDIT_CHECK_SUBMIT";

export const SET_SIGNUP_FOR_ADDITIONAL_PROPERTIES_OR_ICPS =
  "appUserState/SET_SIGNUP_FOR_ADDITIONAL_PROPERTIES_OR_ICPS";

export const SET_PAYMENT_METHOD = "appUserState/SET_PAYMENT_METHOD";

export const SET_FIXED_PAYMENT_DAY = "appUserState/SET_FIXED_PAYMENT_DAY";

export const FORM_CAR_REGISTRATION_SUBMIT =
  "appUserState/FORM_CAR_REGISTRATION_SUBMIT";

export const SET_IS_EXISTING_CUSTOMER = "appUserState/SET_IS_EXISTING_CUSTOMER";
