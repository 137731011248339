export const SET_RESIDENTIAL_ELECTRICITY_OFFER =
  "residentialOffers/SET_RESIDENTIAL_ELECTRICITY_OFFER";

export const SET_RESIDENTIAL_GAS_OFFER =
  "residentialOffer/SET_RESIDENTIAL_GAS_OFFER";

export const SET_RESIDENTIAL_LPG_OFFER =
  "residentialOffer/SET_RESIDENTIAL_LPG_OFFER";

export const SET_RESIDENTIAL_OFFERS = "residentialOffer/SET_RESIDENTIAL_OFFERS";
