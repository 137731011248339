import {
  SET_CUSTOMER_TYPE,
  SET_GAS_AVAILABILITY,
  SET_GAS_SELECTION,
  SET_PLAN_USAGE_TYPE,
  SET_PRODUCT_SELECTION,
  SET_RECENTLY_FAILED_API,
  SUBMIT_OCCUPANCY_FORM,
  SUBMIT_PLAN_SELECT_FORM,
  UPDATE_PRODUCT_SELECTION,
} from "actions/appState/appStateActionTypes";
import {
  SET_CALLBACK_REFERRER_URL,
  UNSET_CALLBACK_REFERRER_URL,
} from "actions/callbackPageActionTypes";
import { PRODUCT_GAS } from "helpers/constants";
import initialState from "./initialState";

export default function appStateReducer(state = initialState.appState, action) {
  switch (action.type) {
    case SET_CUSTOMER_TYPE: {
      return Object.assign({}, state, { customerType: action.payload });
    }

    case SET_PRODUCT_SELECTION: {
      return Object.assign({}, state, { products: action.payload });
    }

    case SET_CALLBACK_REFERRER_URL: {
      return Object.assign({}, state, { callbackReferrerUrl: action.payload });
    }

    case UNSET_CALLBACK_REFERRER_URL: {
      return Object.assign({}, state, { callbackReferrerUrl: "" });
    }

    case UPDATE_PRODUCT_SELECTION: {
      const productsToBeSelected = action.payload;
      const products = state.products.map((product) => {
        const isSelected = productsToBeSelected.includes(product.name);
        return {
          ...product,
          isSelected,
        };
      });
      return Object.assign({}, state, { products });
    }

    case SET_GAS_SELECTION: {
      const products = state.products.map((product) => {
        if (product.name === PRODUCT_GAS) {
          return {
            ...product,
            isSelected: action.payload,
          };
        }
        return product;
      });
      return Object.assign({}, state, { products });
    }

    case SET_GAS_AVAILABILITY: {
      const products = state.products.map((product) => {
        if (product.name === PRODUCT_GAS) {
          return {
            ...product,
            isAvailable: action.payload,
          };
        }
        return product;
      });
      return Object.assign({}, state, { products });
    }

    case SET_PLAN_USAGE_TYPE: {
      return Object.assign({}, state, { planUsageType: action.payload });
    }

    case SUBMIT_OCCUPANCY_FORM: {
      return Object.assign({}, state, { ...action.payload });
    }

    case SUBMIT_PLAN_SELECT_FORM: {
      return Object.assign({}, state, { ...action.payload });
    }

    case SET_RECENTLY_FAILED_API:
      return Object.assign({}, state, { recentlyFailedApi: action.payload });

    default:
      return state;
  }
}
