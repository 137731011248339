import {
  SET_ECOMMERCE_OBJECT_BUSINESS,
  SET_ECOMMERCE_OBJECT_RESIDENTIAL,
} from "actions/gtmActionTypes";
import initialState from "./initialState";

export default function gtmReducer(state = initialState.gtmState, action) {
  switch (action.type) {
    case SET_ECOMMERCE_OBJECT_BUSINESS: {
      return Object.assign({}, state, { businessEcommerce: action.payload });
    }
    case SET_ECOMMERCE_OBJECT_RESIDENTIAL: {
      return Object.assign({}, state, { residentialEcommerce: action.payload });
    }
    default:
      return state;
  }
}
