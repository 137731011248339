import { persistor, store } from "store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "styles/join-form-sass/all.scss";
import "styles/globals.css";

function MyApp({ Component, pageProps }) {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Component {...pageProps} />
      </PersistGate>
    </Provider>
  );
}

export default MyApp;
