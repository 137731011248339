import {
  SET_RESIDENTIAL_ELECTRICITY_OFFER,
  SET_RESIDENTIAL_GAS_OFFER,
  SET_RESIDENTIAL_LPG_OFFER,
  SET_RESIDENTIAL_OFFERS,
} from "actions/residentialOffer/residentialOfferActionTypes";
import initialState from "./initialState";

export default function residentialOffersReducer(
  state = initialState.residentialOffers,
  action,
) {
  switch (action.type) {
    case SET_RESIDENTIAL_ELECTRICITY_OFFER: {
      return Object.assign({}, state, {
        electricityOffer: { ...action.payload },
      });
    }
    case SET_RESIDENTIAL_GAS_OFFER: {
      return Object.assign({}, state, {
        naturalGasOffer: { ...action.payload },
      });
    }
    case SET_RESIDENTIAL_LPG_OFFER: {
      return Object.assign({}, state, {
        bottledGasOffer: { ...action.payload },
      });
    }
    case SET_RESIDENTIAL_OFFERS: {
      return Object.assign({}, state, {
        ...action.payload,
      });
    }
    default:
      return state;
  }
}
