import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialState";

const { carRegistration: initialCarRegistrationState } = initialState;

const carRegistrationSlice = createSlice({
  name: "carRegistration",
  initialState: initialCarRegistrationState,
  reducers: {
    setCarRegistrationData(state, action) {
      return { ...state, ...action.payload };
    },
    unsetCarRegistrationData(state, action) {
      return { ...initialCarRegistrationState, ...action.payload };
    },
  },
});

export const { setCarRegistrationData, unsetCarRegistrationData } =
  carRegistrationSlice.actions;

export default carRegistrationSlice.reducer;
