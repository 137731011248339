import { CREATE_ZENDESK_TICKET, SET_UTM_VALUES } from "actions/zendeskActionTypes";
import initialState from "./initialState";

export default function zendeskReducer(
  state = initialState.zendeskState,
  action,
) {
  switch (action.type) {
    case CREATE_ZENDESK_TICKET:
      return Object.assign({}, state, { ticketId: action.payload });
    case SET_UTM_VALUES:
      return Object.assign({}, state, { utm: action.payload });

    default:
      return state;
  }
}
