import {
  SET_BUSINESS_OFFERS,
  UNSET_BUSINESS_OFFERS,
} from "actions/businessOfferActionTypes";
import initialState from "./initialState";

export default function businessOffersReducer(
  state = initialState.businessOffers,
  action,
) {
  switch (action.type) {
    case UNSET_BUSINESS_OFFERS: {
      return Object.assign({}, initialState.businessOffers);
    }

    case SET_BUSINESS_OFFERS: {
      return Object.assign({ ...state }, { ...action.payload });
    }

    default:
      return state;
  }
}
