import { SET_RESIDENTIAL_PLAN_TERM } from "actions/residentialPlanConfigActionTypes";
import initialState from "./initialState";

export default function residentialPlanConfigReducer(
  state = initialState.residentialPlanConfig,
  action,
) {
  switch (action.type) {
    case SET_RESIDENTIAL_PLAN_TERM: {
      const { payload: termCodeName } = action;
      const termData = state.termData.map((termItem) => {
        return { ...termItem, isSelected: termItem.codeName === termCodeName };
      });
      return Object.assign({}, state, { termData });
    }
    default:
      return state;
  }
}
