import {
  FORM_CAR_REGISTRATION_SUBMIT,
  FORM_CREDIT_CHECK_SUBMIT,
  FORM_PERSONAL_DETAILS_SUBMIT,
  SET_FIXED_PAYMENT_DAY,
  SET_IS_EXISTING_CUSTOMER,
  SET_PAYMENT_METHOD,
  SET_SIGNUP_FOR_ADDITIONAL_PROPERTIES_OR_ICPS,
} from "actions/appUserStateActionTypes";
import { SET_CALLBACK_REASON } from "actions/callbackPageActionTypes";
import initialState from "./initialState";

export default function appUserStateReducer(
  state = initialState.appUserState,
  action,
) {
  switch (action.type) {
    case FORM_PERSONAL_DETAILS_SUBMIT: {
      return Object.assign({}, state, action.payload);
    }
    case FORM_CREDIT_CHECK_SUBMIT: {
      return Object.assign({}, state, action.payload);
    }
    case FORM_CAR_REGISTRATION_SUBMIT: {
      return Object.assign({}, state, action.payload);
    }
    case SET_SIGNUP_FOR_ADDITIONAL_PROPERTIES_OR_ICPS: {
      return Object.assign({}, state, {
        signupForAdditionalPropertiesOrIcps: action.payload,
      });
    }
    case SET_CALLBACK_REASON: {
      return Object.assign({}, state, {
        callbackReason: action.payload,
      });
    }
    case SET_PAYMENT_METHOD: {
      return Object.assign({}, state, { paymentMethod: action.payload });
    }
    case SET_FIXED_PAYMENT_DAY: {
      return Object.assign({}, state, { fixedPaymentDay: action.payload });
    }
    case SET_IS_EXISTING_CUSTOMER: {
      return Object.assign({}, state, {
        isExistingCustomer: String(action.payload),
      });
    }
    default:
      return state;
  }
}
