const muleApiBaseUrl = process.env.NEXT_PUBLIC_MULE_API_BASE_URL;
const websiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;

export const API_ENDPOINTS = Object.freeze({
  getOffers: `${muleApiBaseUrl}/online/pricing/getOffers`,
  carRegistration: `${muleApiBaseUrl}/productAndContract/evpp-eligibility/vehicle/:plate`,
});

// callback reason codes
export const CALLBACK_REASON_UNSPECIFIED = "CALLBACK_REASON_UNSPECIFIED";
export const CALLBACK_REASON_MANUAL_ADDRESS = "CALLBACK_REASON_MANUAL_ADDRESS";
export const CALLBACK_REASON_MULTI_CONNECT = "CALLBACK_REASON_MULTI_CONNECT";
export const CALLBACK_REASON_BOTTLED_GAS = "CALLBACK_REASON_BOTTLED_GAS";
export const CALLBACK_REASON_TOU = "CALLBACK_REASON_TOU";
export const CALLBACK_REASON_DECOMMISSIONED = "CALLBACK_REASON_DECOMMISSIONED";
export const CALLBACK_REASON_UNMETERED = "CALLBACK_REASON_UNMETERED";
export const CALLBACK_REASON_RESIDENTIAL = "CALLBACK_REASON_RESIDENTIAL";
export const CALLBACK_REASON_NO_RATES = "CALLBACK_REASON_NO_RATES";
export const CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM =
  "CALLBACK_REASON_NO_RATES_FOR_SELECTED_TERM";
export const CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS =
  "CALLBACK_REASON_LPG_REQUOTE_PPD_PLANS";

export const ADDRESS_TYPE_ADDRESS_FINDER = "ADDRESS_TYPE_ADDRESS_FINDER";
export const ADDRESS_TYPE_REGISTRY = "ADDRESS_TYPE_REGISTRY";
export const ADDRESS_TYPE_MANUAL = "ADDRESS_TYPE_MANUAL";

export const MIN_BANK_ACCOUNT_NUMBER_LENGTH = 18;

export const CUSTOMER_TYPE_RESIDENTIAL = "residential";
export const CUSTOMER_TYPE_BUSINESS = "business";

export const BUSINESS_TYPE_SOLE_TRADER = "BUSINESS_TYPE_SOLE_TRADER";
export const BUSINESS_TYPE_COMPANY = "BUSINESS_TYPE_COMPANY";
export const BUSINESS_TYPE_OTHER = "BUSINESS_TYPE_OTHER";

export const DISPLAY_NAME_SOLE_TRADER = "Sole Trader";
export const DISPLAY_NAME_COMPANY = "Company";
export const DISPLAY_NAME_OTHER = "Other";

export const smeOfferErrorCodes = Object.freeze({
  tou: "INVALID_ICP_IDENTIFIER_TOU",
  decommissioned: "INVALID_ICP_IDENTIFIER_DECOMMISSIONED",
  residential: "INVALID_ICP_IDENTIFIER_NOT_SME",
  unmetered: "INVALID_ICP_IDENTIFIER_UNMETERED",
});

// external links
export const links = Object.freeze({
  basicTerms: "/basic-plan-terms",
  plusTerms: "/plus-plan-terms",
  evPlusTerms: "/terms/energy-ev-plan-terms",
  businessTerms: "/business-energy-plan-terms",
  standardTerms: "/standard-terms",
  businessStandardTerms: "/terms-and-conditions",
  directDebitTerms: "/direct-debit-terms",
  creditCardTerms: "/app-credit-card-terms",
  flybuys: "/flybuys-standard-terms",
  powerShout: "/powershout-info",
  privacyPolicy: "/privacy-policy",
  rewardTerms: "/rewards-terms",
  businessRewardsTerms: "/business-rewards-terms",
});

// routes
const basePath = "/join";

export const appRoutes = Object.freeze({
  customerType: "/join",
});

export const appResidentialRoutes = Object.freeze({
  address: `${basePath}/residential/address`,
  addressIcp: `${basePath}/residential/address/icp`,
  addressManual: `${basePath}/residential/address/manual`,
  fuel: `${basePath}/residential/fuel`,
  occupancy: `${basePath}/residential/occupancy`,
  contactDetails: `${basePath}/residential/contact-details`,
  callback: `${basePath}/residential/callback`,
  planSelect: `${basePath}/residential/plans`,
  termSelect: `${basePath}/residential/term-select`,
  creditCheck: `${basePath}/residential/id`,
  property: `${basePath}/residential/property`,
  gas: `${basePath}/residential/gas-information`,
  payment: `${basePath}/residential/payment-options`,
  signup: `${basePath}/residential`,
  success: `${basePath}/residential/success`,
  callbackSuccess: `${basePath}/residential/confirm`,
  carRegistration: `${basePath}/residential/car-registration`,
});

export const appBusinessRoutes = Object.freeze({
  businessDetails: `${basePath}/business/type`,
  address: `${basePath}/business/address`,
  addressIcp: `${basePath}/business/address/icp`,
  addressManual: `${basePath}/business/address/manual`,
  fuel: `${basePath}/business/fuel`,
  contactDetails: `${basePath}/business/contact-details`,
  termSelect: `${basePath}/business/term-select`,
  creditCheck: `${basePath}/business/id`,
  property: `${basePath}/business/property`,
  gas: `${basePath}/business/gas-information`,
  payment: `${basePath}/business/payment-options`,
  callback: `${basePath}/business/callback`,
  success: `${basePath}/business/success`,
  callbackSuccess: `${basePath}/business/confirm`,
});

export const EBILLING_DISCOUNT = 1;
export const CREDIT_CARD_DISCOUNT = 1;
export const DIRECT_DEBIT_DISCOUNT = 2;
export const BUSINESS_DIRECT_DEBIT_DISCOUNT = 1;
export const TWELVE_MONTH_FIXED_TERM_DISCOUNT = 3;
export const DUAL_FUEL_DISCOUNT = 5;
export const MULTI_CONNECT_DISCOUNT = 3;
export const BUSINESS_PLAN_TERM_FIXED24_DISCOUNT = 4;
export const BUSINESS_PLAN_TERM_FIXED12_DISCOUNT = 2;

export const PRODUCT_ELECTRICITY = "Electricity";
export const PRODUCT_GAS = "Natural Gas";
export const PRODUCT_LPG = "LPG";
export const PRODUCT_LPG_FULL_NAME = "Bottled Gas";

export const PRODUCT_ELECTRICITY_DISPLAY_NAME = PRODUCT_ELECTRICITY;
export const PRODUCT_GAS_DISPLAY_NAME = PRODUCT_GAS;
export const PRODUCT_LPG_DISPLAY_NAME = "Bottled Gas (LPG)";

export const NATURAL_GAS_ABBREVIATION = "Gas";
export const BOTTLED_GAS_ABBREVIATION = "LPG";

export const BUSINESS_PLAN_TERM_FIXED24 = "BUSINESS_PLAN_TERM_FIXED24";
export const BUSINESS_PLAN_TERM_FIXED12 = "BUSINESS_PLAN_TERM_FIXED12";
export const BUSINESS_PLAN_TERM_FLEXI = "BUSINESS_PLAN_TERM_FLEXI";

export const BUSINESS_PLAN_TERM_PERIOD_FIXED24 = "24";
export const BUSINESS_PLAN_TERM_PERIOD_FIXED12 = "12";
export const BUSINESS_PLAN_TERM_PERIOD_FLEXI = null;

export const RESIDENTIAL_PLAN_TERM_FIXED24 = "RESIDENTIAL_PLAN_TERM_FIXED24";
export const RESIDENTIAL_PLAN_TERM_FIXED12 = "RESIDENTIAL_PLAN_TERM_FIXED12";
export const RESIDENTIAL_PLAN_TERM_FLEXI = "RESIDENTIAL_PLAN_TERM_FLEXI";

export const PLAN_CONFIG_TERM = "PLAN_CONFIG_TERM";
export const PLAN_CONFIG_TERM_FIXED = "PLAN_CONFIG_TERM_FIXED";
export const PLAN_CONFIG_TERM_FLEXI = "PLAN_CONFIG_TERM_FLEXI";

export const PLUS_ENERGY_EV_CAMPAIGN_CODE = "PLSENERGYEV12";
export const PLUS_PLAN_FIXED_TERM_CODES = ["PLUSG12", "PLUSL1250", "PLUSE12"];

export const DEFAULT_CAMPAIGN_CODES = [
  "PLUSE",
  "PLUSG",
  "PLUSL",
  ...PLUS_PLAN_FIXED_TERM_CODES,
  PLUS_ENERGY_EV_CAMPAIGN_CODE,
];

export const PLAN_TYPE_PLUS = "NPA-PLUS";
export const PLAN_TYPE_PLUS_FLEXI = "NPA-PLUS-FLEXI";
export const PLAN_TYPE_PLUS_FIXED = "NPA-PLUS-FIXED";
export const PLAN_TYPE_BASIC = "NPA-BASIC";
export const PLAN_TYPE_EV_PLUS = "EV_PLUS";

export const EV_EXISTING_CUSTOMER = "EV Existing";
export const EV_NEW_CUSTOMER = "EV New";
export const NON_EV_CUSTOMER = "Non EV";

export const PLAN_TYPE_TO_PLAN_NAME = Object.freeze({
  [PLAN_TYPE_PLUS]: "plusPlan",
  [PLAN_TYPE_PLUS_FLEXI]: "plusPlan",
  [PLAN_TYPE_PLUS_FIXED]: "plusPlan",
  [PLAN_TYPE_BASIC]: "basicPlan",
  [PLAN_TYPE_EV_PLUS]: "evPlan",
});

export const PLAN_LOW_USAGE_CODES = ["KW", "CV", "LMP", "N01", "LPG"];
export const PLAN_HIGH_USAGE_CODES = ["HH", "LF", "LMP", "N01", "LPG"];

export const PLAN_USAGE_LOW = "LOW";
export const PLAN_USAGE_HIGH = "HIGH";

export const PAYMENT_DAY_FIXED = "paymentDay_fixed";
export const PAYMENT_DAY_NOT_FIXED = "paymentDay_notfixed";

export const PAYMENT_METHOD_CREDIT_CARD = "paymentMethod_cc";
export const PAYMENT_METHOD_DIRECT_DEBIT = "paymentMethod_dd";
export const PAYMENT_METHOD_MANUAL = "paymentMethod_manual";

export const PROPERTY_SITUATION_MOVE = "MOVE";
export const PROPERTY_SITUATION_TRANSFER = "TRANSFER";

export const PREVENT_ACCESS_REASON_DOGS = "preventAccess_dogs";
export const PREVENT_ACCESS_REASON_TRUCKS = "preventAccess_trucks";
export const PREVENT_ACCESS_REASON_SECURITY_CODE = "preventAccess_securitycode";
export const PREVENT_ACCESS_REASON_METER_INSIDE = "preventAccess_meterinside";
export const PREVENT_ACCESS_REASON_STEPS = "preventAccess_steps";
export const PREVENT_ACCESS_REASON_STEEP_DRIVEWAY =
  "preventAccess_steepDriveway";
export const PREVENT_ACCESS_REASON_GATE = "preventAccess_gate";
export const PREVENT_ACCESS_REASON_OTHER = "preventAccess_other";

export const WELLBEING_AFFECTED_AGE = "Age";
export const WELLBEING_AFFECTED_HEALTH = "Health";
export const WELLBEING_AFFECTED_DISABILITY = "Disability";

export const SUPPLY_POINT_TYPE_ELECTRICITY = "ELECTRICITY";
export const SUPPLY_POINT_TYPE_GAS = "GAS";
export const SUPPLY_POINT_TYPE_LPG = "LPG";

export const LPG_NO_OF_BOTTLES_SETUP_NONE = "None";
export const LPG_NO_OF_BOTTLES_SETUP_2 = "2";
export const LPG_NO_OF_BOTTLES_SETUP_2_MORE = "2 or more";

export const LPG_NO_OF_ORDER_2 = "2";
export const LPG_NO_OF_ORDER_NONE = "None";

export const LPG_LOCATION_BACK = "BACK-CENTRE";
export const LPG_LOCATION_LEFT_SIDE = "SIDE-LEFT";
export const LPG_LOCATION_RIGHT_SIDE = "SIDE-RIGHT";
export const LPG_LOCATION_FRONT = "FRONT-CENTRE";

export const LPG_SUPPLIER_ELGAS = "Elgas";
export const LPG_SUPPLIER_FRANK_ENERGY = "Frank Energy";
export const LPG_SUPPLIER_ENERGY_ONLINE = "Energy Online";
export const LPG_SUPPLIER_GENESIS_ENERGY = "Genesis Energy";
export const LPG_SUPPLIER_KING_COUNTRY_ENERGY = "King Country Energy";
export const LPG_SUPPLIER_NOVA = "Nova";
export const LPG_SUPPLIER_ONGAS = "OnGas";
export const LPG_SUPPLIER_PULSE = "Pulse";
export const LPG_SUPPLIER_ROCK_GAS = "Rock Gas";
export const LPG_SUPPLIER_TRUST_POWER = "Trustpower";
export const LPG_SUPPLIER_DONT_KNOW = "Unknown";

export const LPG_BOTTLE_SIZE_45 = "45";
export const LPG_BOTTLE_SIZE_9 = "9";
export const LPG_BOTTLE_SIZE_12 = "12";
export const LPG_BOTTLE_SIZE_18 = "18";
export const LPG_BOTTLE_SIZE_27 = "27";
export const LPG_BOTTLE_SIZE_90 = "90";
export const LPG_BOTTLE_SIZE_15 = "15";
export const LPG_BOTTLE_SIZE_20 = "20";
export const LPG_BOTTLE_SIZE_OTHER = "other";

export const DEFAULT_CAMPAIGN_CODE_DEFINITIONS = [
  // plus plans, flexible term
  {
    planType: PLAN_TYPE_PLUS,
    planTerm: RESIDENTIAL_PLAN_TERM_FLEXI,
    planProduct: PRODUCT_ELECTRICITY,
    planCode: "PLUSE",
    planCredit: 0,
  },
  {
    planType: PLAN_TYPE_PLUS,
    planTerm: RESIDENTIAL_PLAN_TERM_FLEXI,
    planProduct: PRODUCT_GAS,
    planCode: "PLUSG",
    planCredit: 0,
  },
  {
    planType: PLAN_TYPE_PLUS,
    planTerm: RESIDENTIAL_PLAN_TERM_FLEXI,
    planProduct: PRODUCT_LPG,
    planCode: "PLUSL",
    planCredit: 0,
  },
  // plus plans, fixed term
  {
    planType: PLAN_TYPE_PLUS,
    planTerm: RESIDENTIAL_PLAN_TERM_FIXED12,
    planProduct: PRODUCT_ELECTRICITY,
    planCode: "PLUSE12",
    planCredit: 0,
  },
  {
    planType: PLAN_TYPE_PLUS,
    planTerm: RESIDENTIAL_PLAN_TERM_FIXED12,
    planProduct: PRODUCT_GAS,
    planCode: "PLUSG12",
    planCredit: 0,
  },
  {
    planType: PLAN_TYPE_PLUS,
    planTerm: RESIDENTIAL_PLAN_TERM_FIXED12,
    planProduct: PRODUCT_LPG,
    planCode: "PLUSL1250",
    planCredit: 50,
  },
  // basic plans, fixed term
  {
    planType: PLAN_TYPE_BASIC,
    planTerm: RESIDENTIAL_PLAN_TERM_FIXED12,
    planProduct: PRODUCT_ELECTRICITY,
    planCode: "BASCE12",
    planCredit: 0,
  },
  {
    planType: PLAN_TYPE_BASIC,
    planTerm: RESIDENTIAL_PLAN_TERM_FIXED12,
    planProduct: PRODUCT_GAS,
    planCode: "BASCG12",
    planCredit: 0,
  },
  {
    planType: PLAN_TYPE_BASIC,
    planTerm: RESIDENTIAL_PLAN_TERM_FIXED12,
    planProduct: PRODUCT_LPG,
    planCode: "BASCL12",
    planCredit: 0,
  },
  // ev plus plans, fixed term
  {
    planType: PLAN_TYPE_EV_PLUS,
    planTerm: RESIDENTIAL_PLAN_TERM_FIXED12,
    planProduct: PRODUCT_ELECTRICITY,
    planCode: "PLSENERGYEV12",
    planCredit: 0,
  },
  {
    planType: PLAN_TYPE_EV_PLUS,
    planTerm: RESIDENTIAL_PLAN_TERM_FIXED12,
    planProduct: PRODUCT_GAS,
    planCode: "PLUSG12",
    planCredit: 0,
  },
  {
    planType: PLAN_TYPE_EV_PLUS,
    planTerm: RESIDENTIAL_PLAN_TERM_FIXED12,
    planProduct: PRODUCT_LPG,
    planCode: "PLUSL1250",
    planCredit: 50,
  },
];

export const DUAL_FUEL_ELEC_BONUS_CREDIT_PLAN_CODE = "PLUSE12150";
export const DUAL_FUEL_ELEC_BONUS_CREDIT = 100;

export const productPrices = [
  {
    services: "electricity",
    contract: "flexi",
    plan: "npa-plus",
    mean: "1359.92",
  },
  {
    services: "electricity",
    contract: "fixed",
    plan: "npa-plus",
    mean: "921.15",
  },
  {
    services: "electricity",
    contract: "fixed",
    plan: "npa-basic",
    mean: "610.25",
  },
  {
    services: "electricity|natural gas",
    contract: "flexi",
    plan: "npa-plus",
    mean: "2049.30",
  },
  {
    services: "electricity|natural gas",
    contract: "fixed",
    plan: "npa-plus",
    mean: "1822.14",
  },
  {
    services: "electricity|natural gas",
    contract: "fixed",
    plan: "npa-basic",
    mean: "1276.31",
  },

  {
    services: "electricity|natural gas|lpg",
    contract: "flexi",
    plan: "npa-plus",
    mean: "1670.70",
  },
  {
    services: "electricity|natural gas|lpg",
    contract: "fixed",
    plan: "npa-plus",
    mean: "1014.04",
  },
  {
    services: "electricity|lpg",
    contract: "flexi",
    plan: "npa-plus",
    mean: "1173.96",
  },
  {
    services: "electricity|lpg",
    contract: "fixed",
    plan: "npa-plus",
    mean: "508.51",
  },
  {
    services: "electricity|lpg",
    contract: "fixed",
    plan: "npa-basic",
    mean: "461.72",
  },
  {
    services: "natural gas",
    contract: "flexi",
    plan: "npa-plus",
    mean: "1483.24",
  },
  {
    services: "natural gas",
    contract: "fixed",
    plan: "npa-plus",
    mean: "1160.31",
  },
  {
    services: "natural gas",
    contract: "fixed",
    plan: "npa-basic",
    mean: "1140.87",
  },
  {
    services: "natural gas|lpg",
    contract: "flexi",
    plan: "npa-plus",
    mean: "1149.56",
  },
  {
    services: "natural gas|lpg",
    contract: "fixed",
    plan: "npa-plus",
    mean: "995.47",
  },
  {
    services: "natural gas|lpg",
    contract: "fixed",
    plan: "npa-basic",
    mean: "710.27",
  },
  {
    services: "lpg",
    contract: "fixed",
    plan: "npa-plus",
    mean: "577.85",
  },
  {
    services: "lpg",
    contract: "fixed",
    plan: "npa-basic",
    mean: "577.85",
  },
  { services: "lpg", contract: "flexi", plan: "npa-plus", mean: "55.13" },
];

export const STORAGE_KEY = "npaAuth";
export const LIVE_CHAT_TOKEN_KEY = "liveChatAuth";

export const residentialApisForCallback = ["getIcpList", "accessToken"];

export const applicableDiscountsForBusinessOffers = {
  multiConnect: "MULTIDISC",
  directDebit: "DD DISCOUNT",
  fixedTermDiscount12: "FIXED DISC12",
  fixedTermDiscount24: "FIXED DISC24",
};

export const windcaveCallbackUrls = {
  approved: `${websiteUrl}${appResidentialRoutes.payment}?cc-validation=approved`,
  declined: `${websiteUrl}${appResidentialRoutes.payment}?cc-validation=declined`,
  cancelled: `${websiteUrl}${appResidentialRoutes.payment}?cc-validation=cancelled`,
};
