const THEME = {
  glass: "glass",
  orange: "orange",
  white: "white",
  grey: "grey",
  dark: "dark",
  light: "light",
};

const VARIANT = {
  dark: "dark",
  darkTransparent: "dark-transparent",
  light: "light",
  lightTransparent: "light-transparent",
  orange: "orange",
  orangeTransparent: "orange-transparent",
  blackTransparent: "black-transparent",
  blackOutlined: "black-outlined",
};

const APP_ROUTES = Object.freeze({
  faqs: "/help/faqs",
  news: "/about/news",
  ge_climate_hub_business:
    "/about/sustainability/climate-change-hub/your-business",
  ge_climate_hub_country:
    "/about/sustainability/climate-change-hub/your-country",
  geclimatehub: "/about/sustainability/climate-change-hub/yourself",
  geblog: "/tips-and-tricks/articles",
  search: "/search",
});

const SHARE_PRICE_API = {
  urls: [
    "/cms/nzxesb/v1/priceQuote.json?f=prices_at_time,last,move_percent",
    "/cms/nzxesb/v1/priceHistory.json?f=close,date,volume",
    "/cms/nzxesb/v1/indicesHistory.json?f=gross",
    "/cms/nzxesb/v1/priceSummary.json?f=high,low",
  ],
  months: [1, 3, 12],
  refetchInterval: 1000 * 60 * 20, // 20 mins
};
const ACCESS_TOKEN_STORAGE_KEY = "npaAuth";

const DOCUMENT_ACTION_TYPES = Object.freeze({
  external: "external",
  file: "file",
  video: "video",
});

const FORM_STACK_SUBMISSION_URL =
  "https://genesisenergy.formstack.com/forms/index.php";

const HYDRO = {
  queryId: "hydroSummary",
  cacheTime: 1000 * 60 * 5, // 5 mins
  staleTime: 1000 * 60 * 4, // 5 mins
  summaryUrl: "/genesis/WebGraphs/SummaryTable.xml",
  detailPageUrlPath: "/about/generation/rivers-lakes-and-rainfall/",
  gifUrlPath: "/genesis/WebGraphs/",
  type: {
    // matches cms module values
    rainfall: "rainfall",
    river: "flow",
    lake: "reduced level",
    spillFlow: "spill flow",
  },
};
const BOOK_CALL_STATUS_URL =
  "https://03924e610d6f4bdc.cbridgert.vhtcloud.com/api/widget/dfb9d64e9758149a2a7ce9488e144913";

const ZENDESK_API_URL = "https://genesisenergynz.zendesk.com/api/v2";

const ADDRESS_FINDER = {
  autocomplete: "/api/nz/address/autocomplete",
  baseUrl: "https://api.addressfinder.io",
  metadata: "/api/nz/address/metadata",
};

const UTM_CODES = {
  source: "utm_source",
  medium: "utm_medium",
  campaign: "utm_campaign",
  gclid: "gclid",
  dclid: "dclid",
};

export {
  ACCESS_TOKEN_STORAGE_KEY,
  APP_ROUTES,
  BOOK_CALL_STATUS_URL,
  DOCUMENT_ACTION_TYPES,
  FORM_STACK_SUBMISSION_URL,
  HYDRO,
  SHARE_PRICE_API,
  THEME,
  VARIANT,
  ZENDESK_API_URL,
  ADDRESS_FINDER,
  UTM_CODES,
};
