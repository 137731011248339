import {
  FORM_BUSINESS_TYPE_SUBMIT,
  SET_BUSINESS_TYPE,
} from "actions/businessStateActionTypes";
import initialState from "./initialState";

export default function businessStateReducer(
  state = initialState.businessState,
  action,
) {
  switch (action.type) {
    case FORM_BUSINESS_TYPE_SUBMIT: {
      return Object.assign({}, state, action.payload);
    }
    case SET_BUSINESS_TYPE: {
      return Object.assign({}, state, { businessType: action.payload });
    }
    default:
      return state;
  }
}
