import {
  SET_ICP_DETAILS,
  UNSET_ICP_DETAILS,
} from "actions/icpState/icpActionTypes";
import initialState from "./initialState";

export default function icpReducer(state = initialState.icp, action) {
  switch (action.type) {
    case SET_ICP_DETAILS: {
      return Object.assign({}, state, action.payload);
    }
    case UNSET_ICP_DETAILS: {
      return Object.assign({}, initialState.icp);
    }
    default:
      return state;
  }
}
